<template>
  <v-container
    id="DocumentDownload"
    tag="section"
  >
    <v-row align="center"
        justify="center">
      <v-col cols="12" align="center" class="ml-7 pa-0">
        <v-card flat max-width="1000">
          <v-row align="start">
            <v-col align="start" cols="12" class="pa-0 ma-1">
              <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.DocumentDownload') }}</strong></span>
              <v-divider></v-divider>
            </v-col>
            <!-- <v-img
              :src="require(`@/assets/MeeTeeMeeNgern/1000x420px/joinus_1000x420.jpg`)"
              height="100%">
            </v-img> -->
            <v-col align="start" cols="12" class="pa-0 ma-1">
              <Document-Download-Feed-Card
                v-for="(pro, i) in vDataTable.data"
                :key="pro.skey"
                :size="layout[i]"
                :value="pro"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
    components: {
      DocumentDownloadFeedCard: () => import('@/components/DocumentDownloadFeedCard'),
    },
    async created () {
      await this.retrieveFrontDocumentDetail(localStorage.getItem('language'))
      this.utils.UtilsAddVisitCountMenu(this.currentRouteName, 0) /* 1 visit count menu */
    },
    data: () => ({
      utils: require('@/utils'), /* 2 visit count menu */
      layout: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      layoutProducgt: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      page: 1,
      /* DocumentDownload: require('@/data/DocumentDownload.json'),
      product: require('@/data/product.json'), */
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
    }),
    watch: {
      page () {
        window.scrollTo(0, 0)
      },
    },
    computed: {
      currentRouteName () { /* 3 visit count menu */
        return this.$route.name
      }
    },
    methods: {
      async selectLanguage (language) {
        await this.retrieveFrontDocumentDetail(language)
      },
      async addVisitCountMenu (currentRouteName, refSkey) {
        await this.axios.post(process.env.VUE_APP_API + '/visit_count/addVisitCountMenu' + process.env.VUE_APP_DATABASE_TYPE, {
          win_name: currentRouteName,
          ref_skey: refSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              console.log(response.data, 'response.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      async retrieveFrontDocumentDetail (languageID) {
        var aLimitShow = this.limitShow
        if (!aLimitShow) { aLimitShow = 0 }
        await this.axios.post(process.env.VUE_APP_API + '/document_detail/retrieveFrontDocumentDetail' + process.env.VUE_APP_DATABASE_TYPE, {
          limit: aLimitShow,
          language_id: languageID,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
              console.log(this.vDataTable.data, 'this.vDataTable.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
